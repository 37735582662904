import '../../App.css';
import id3856000 from './images/id3856000.jpg';
import id3856001 from './images/id3856001.jpg';
import id3856002 from './images/id3856002.jpg';
import id3856004 from './images/id3856004.jpg';
import id3856005 from './images/id3856005.jpg';
import id3856006 from './images/id3856006.jpg';
import id3856007 from './images/id3856007.jpg';
import id3856008 from './images/id3856008.jpg';
import id3856009 from './images/id3856009.jpg';
import id3856010 from './images/id3856010.jpg';
import id3856011 from './images/id3856011.jpg';
import id3856110 from './images/id3856110.jpg';
import id3856111 from './images/id3856111.jpg';
import id3856112 from './images/id3856112.jpg';
import id3856113 from './images/id3856113.jpg';
import id3856114 from './images/id3856114.jpg';
import id3856115 from './images/id3856115.jpg';
import separatorAdela from './images/separatorAdela.png';
import separatorVoditsa from './images/separatorVoditsa.png';
import infoIcon from './images/infoIcon.png';
import infoIconBlue from './images/infoIconBlue.png';
import { useNavigate } from 'react-router-dom';




function ShopPage() {

  const navigate = useNavigate();

  return (
    <div className='topOffset'>
      <div className='productSeryesStyle productSeryesStyleAdellaSunny'>
        <b>Козметика за коса и тяло<br></br>Saffron Adela Sunny</b>
      </div>
      <div className='productSeparatorStyle'>
        <img className='productSepparatorImg' src={separatorAdela}></img>
      </div>
      <div className='productCategoryContiner'>
        <div onClick={()=>{navigate('/?productID=3856010');}} className='productListContiner'>
          <div className='price'>цена: <b>39.00лв.</b></div>
          <img className='productListImg' src={id3856010}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Балсам за коса с шафран</i><br/>Saffron Adela Sunny</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856011');}} className='productListContiner'>
          <div className='price'>цена: <b>21.60лв.</b></div>
          <img className='productListImg' src={id3856011}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Душ гел</i> Adela Sunny Saffron Natur Tubereuse &amp; Pear</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856004');}} className='productListContiner'>
        <div className='price'>цена: <b>18.00лв.</b></div>
          <img className='productListImg' src={id3856004}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Душ гел за коса и тяло</i><br/>Saffron Adela Sunny for men</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856001');}} className='productListContiner'>
          <div className='price'>цена: <b>17.00лв.</b></div>
          <img className='productListImg' src={id3856001}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Шампоан с шафран</i><br/>Saffron Adela Sunny</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856002');}} className='productListContiner'>
        <div className='price'>цена: <b>15.00лв.</b></div>
          <img className='productListImg' src={id3856002}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Душ гел с шафран</i><br/>Saffron Adela Sunny</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856000');}} className='productListContiner'>
        <div className='price'>цена: <b>6.00лв.</b></div>
          <img className='productListImg' src={id3856000}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Глицеринов сапун с шафран</i><br/>Saffron Adela Sunny</div></div>
        </div>

        <div onClick={()=>{navigate('/?productID=3856005');}} className='productListContiner'>
        <div className='price'>цена: <b>4.80лв.</b></div>
          <img className='productListImg' src={id3856005}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Шампоан с шафран 50ml</i><br/>Saffron Adela Sunny</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856006');}} className='productListContiner'>
        <div className='price'>цена: <b>4.80лв.</b></div>
          <img className='productListImg' src={id3856006}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Душ гел с шафран 50ml</i><br/>Saffron Adela Sunny</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856007');}} className='productListContiner'>
        <div className='price'>цена: <b>4.80лв.</b></div>
          <img className='productListImg' src={id3856007}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Душ гел с шафран 50ml</i><br/>Saffron Adela Sunny for men</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856008');}} className='productListContiner'>
        <div className='price'>цена: <b>36.10лв.</b></div>
          <img className='productListImg' src={id3856008}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Комплект</i><br/>Saffron Adela Sunny</div></div>
        </div>

        <div onClick={()=>{navigate('/?productID=3856009');}} className='productListContiner'>
        <div className='price'>цена: <b>13.50лв.</b></div>
          <img className='productListImg' src={id3856009}/>
          <img className= 'infoIcon' src={infoIcon}/>
          <div className='productListLabelContainer'><div className='productListLabel'><i>Travel set комплект</i><br/>Saffron Adela Sunny</div></div>
        </div>
      </div> 
      <div className='productSeryesStyle productSeryesStyleVoditsa'>
        <b>Козметика за лице<br></br>Voditsa®</b>
      </div>
      <div className='productSeparatorStyle'>
        <img className='productSepparatorImg' src={separatorVoditsa}></img>
      </div>
      <div className='productCategoryContiner'>
        <div onClick={()=>{navigate('/?productID=3856114');}} className='productListContiner'>
          <div className='price priceVodica'>цена: <b>68.00лв.</b></div>
          <img className='productListImg' src={id3856114}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Illuminating Face Mask</i><br/>Voditsa®</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856111');}} className='productListContiner'>
          <div className='price priceVodica'>цена: <b>63.00лв.</b></div>
          <img className='productListImg' src={id3856111}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Anti-Aging Night Cream</i><br/>Voditsa®</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856112');}} className='productListContiner'>
        <div className='price priceVodica'>цена: <b>59.00лв.</b></div>
          <img className='productListImg' src={id3856112}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Anti-Aging Day Cream</i><br/>Voditsa®</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856113');}} className='productListContiner'>
          <div className='price priceVodica'>цена: <b>49.00лв.</b></div>
          <img className='productListImg' src={id3856113}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Anti-Aging Eye Cream</i><br/>Voditsa®</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856110');}} className='productListContiner'>
          <div className='price priceVodica'>цена: <b>28.00лв.</b></div>
          <img className='productListImg' src={id3856110}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Хидратиращ мист за лице</i><br/>Voditsa®</div></div>
        </div>
        <div onClick={()=>{navigate('/?productID=3856115');}} className='productListContiner'>
          <div className='price priceVodica'>цена: <b>13.00лв.</b></div>
          <img className='productListImg' src={id3856115}/>
          <img className= 'infoIcon' src={infoIconBlue}/>
          <div className='productListLabelContainer productListLabelContainerBlue'><div className='productListLabel'><i>Почистваща мицеларна вода</i> Voditsa®</div></div>
        </div>
        <div className='bottomMarginElement'></div>
      </div>
    </div>
  );
}

export default ShopPage;
